import { isNil, omit } from "lodash";
import { apolloClient } from "~/common/apollo";
import { EVENTS } from "~/common/constants";
import {
  action,
  computed,
  event,
  observable,
  store,
} from "~/common/mobx.decorator";
import { masterStore } from "~/stores";
import { convertStateToString } from "~/common/helpers/mapping.helper";
import { logClickEvent } from "~/common/tracking/event-client.tracking";
import { EVENT_CLICK_ID } from "~/common/tracking/event-click.constant";
import { SEARCH_SHIFT_REQUESTS_QUERY } from "~/common/apollo/queries/shift-request.query";

@store()
class WorkerFindJobStore {
  @observable searchData = null;
  @observable sortKey = "pay_amount";
  @observable sortDirection = "desc";

  @observable token = null;
  @observable jobs = [];
  @observable searchParams = {};
  @observable activeFilter = {};
  @observable filterOptions = null;
  @observable listSaveSearch = [];
  @observable isShowSaveSearchDialog = false;
  @observable searchString;
  @observable totalCount;
  @observable filterValues = {};
  @observable browserLocation = null;
  @observable flatternSaveSearch = {};
  @observable facilityIds = [];

  @computed
  get searchStr() {
    return this.searchString;
  }

  @computed
  get locationInitial() {
    const browserLocation = this.browserLocation;
    if (browserLocation && browserLocation?.state) {
      const location = {
        label: browserLocation?.city
          ? `${browserLocation.city}, ${browserLocation.state}`
          : browserLocation.state,
        value: browserLocation?.city
          ? `${browserLocation.city}, ${browserLocation.state}`
          : browserLocation.state,
        type: browserLocation?.city ? "locationCity" : "locationState",
      };
      return [location];
    }
    return null;
  }

  @computed
  get disciplineVal() {
    return [];
  }

  @computed
  get specialtyOptionsVal() {
    return null;
  }

  @computed
  get stateOptions() {
    return masterStore.licenseStates;
  }

  @computed
  get hideDistance() {
    return !this.filterValues.locationCity;
  }

  @computed
  get professionOptions() {
    return masterStore.profession;
  }

  @computed
  get jobShiftOptions() {
    return masterStore.jobShifts;
  }

  @computed
  get hasNextPage() {
    return this.jobs?.length < this.totalCount && this.jobs?.length > 0;
  }

  @computed
  get specialtyOptions() {
    return masterStore.jobDisciplines;
  }

  @computed
  get isSaveSearch() {
    return this.listSaveSearch?.every((item) => item?.isActive === false);
  }

  @action
  setFilterValues = (values) => {
    this.filterValues = values;
  };

  @action
  resetFilterValue = () => {
    this.resetSearchFilter();
  };

  @action
  resetSearchData = () => {
    this.searchData = null;
  };

  @action
  setSearchStr = (searchInput) => {
    this.searchString = searchInput;
  };

  @action
  disciplineOptions(nameProfession) {
    let compresData = !!nameProfession
      ? Object.values(masterStore?.jobDisciplines?.[nameProfession] || {})
      : Object.assign({}, ...Object.values(masterStore?.jobDisciplines || {}));

    return Object.values(compresData).map((key, value) => ({
      label: key?.long_name,
      value: key.skill_name,
      id: key?.id,
    }));
  }

  @action
  setSearchParams = (newParams) => {
    this.searchParams = { ...this.searchParams, ...newParams };
  };

  @action
  resetSearchFilter = () => {
    // const storedLatitude = localStorage.getItem("browserLatitude");
    // const storedLongitude = localStorage.getItem("browserLongitude");
    // if (storedLongitude && storedLatitude) {
    //   this.filterValues = {
    //     nearBy: {
    //       latitude: storedLatitude,
    //       longitude: storedLongitude,
    //       radius: 50,
    //     },
    //     specialty: [],
    //   };
    // } else {
    this.filterValues = { specialty: [], location: this.locationInitial };
    // }
    return this.filterValues;
  };

  @action
  getFlattenFilter = (value) => {
    return omit(value, ["workerId", "companyId", "id", "radius", "isActive"]);
  };

  @action
  onCloseSearch = () => {
    this.searchData = null;
    this.sortKey = "SCORE";
  };

  @action
  onScrollToEnd = async () => {
    const sortBy = this.sortKey && {
      field: this.sortKey,
      order: this.sortKey === "DISTANCE" ? "asc" : this.sortDirection,
    };

    const response = await apolloClient.query({
      query: SEARCH_SHIFT_REQUESTS_QUERY,
      variables: {
        filter: { ...this.flatternSaveSearch, keyword: this.searchString },
        sortBy,
        limit: 20,
        offset: this.jobs?.length || 0,
        showRelatedFacilityIds: true,
      },
    });

    this.jobs = [
      ...this.jobs,
      ...response?.data?.searchShiftRequests?.shiftRequests,
    ];
    this.facilityIds = response?.data?.searchShiftRequests?.relatedFacilityIds;
  };

  @action
  onUpdateSortKey = async (sortKey) => {
    if (sortKey !== this.sortKey) {
      this.sortKey = sortKey;
      await this.fetchSearchResults();
    }
  };

  @action
  fetchSearchResults = async (filterCondtion) => {
    const sortBy = this.sortKey && {
      field: this.sortKey,
      order: this.sortKey === "DISTANCE" ? "asc" : this.sortDirection,
    };

    const { keywordSearch, nearBySearch, city, ...restFilter } = filterCondtion;

    const profession = restFilter?.profession;
    const skillNameLength = restFilter?.skillName?.length;

    const restValue =
      (!!profession && skillNameLength === 0) ||
      (!!profession && isNil(skillNameLength))
        ? {
            ...restFilter,
            skillName: this.disciplineOptions(profession).map(
              (item) => item?.value
            ),
          }
        : restFilter;

    let flatternSaveSearch = convertStateToString(
      omit(restValue, ["name", "isActive", "id", "specialty"])
    );

    if (
      !flatternSaveSearch?.locationCities &&
      flatternSaveSearch?.locationStates
    ) {
      // const storedLatitude = localStorage.getItem("browserLatitude");
      // const storedLongitude = localStorage.getItem("browserLongitude");
      // if (storedLongitude && storedLatitude) {
      //   flatternSaveSearch = {
      //     ...flatternSaveSearch,
      //     nearBy: {
      //       latitude: storedLatitude,
      //       longitude: storedLongitude,
      //     },
      //   };
      // }
    }

    this.flatternSaveSearch = flatternSaveSearch;

    const response = await apolloClient.query({
      query: SEARCH_SHIFT_REQUESTS_QUERY,
      variables: {
        filter: { ...flatternSaveSearch },
        sortBy,
        limit: 20,
        offset: 0,
        showRelatedFacilityIds: true,
      },
    });

    this.jobs = response?.data?.searchShiftRequests?.shiftRequests;
    this.totalCount = response?.data?.searchShiftRequests?.totalCount;
    this.facilityIds = response?.data?.searchShiftRequests?.relatedFacilityIds;

    // Log event tracking
    logClickEvent(EVENT_CLICK_ID.clickSearchJobsLandingList, {
      jobs: response?.data?.searchShiftRequests?.shiftRequests?.map(
        (item) => item.id
      ),
    });
  };

  @action
  setSortValue = async (key, direction) => {
    this.sortKey = key;
    this.sortDirection = direction;
  };

  @action
  setBrowserLocation = async (value) => {
    this.browserLocation = value;
  };

  @event(EVENTS.authStore.logout)
  userLogout() {
    this.searchData = null;
    this.sortKey = "SCORE";
  }
}

export const workerFindJobStore = new WorkerFindJobStore();
