import { gql } from "@apollo/client";

export const GET_FACILITYS_QUERY = gql`
  query getFacilities(
    $keywordSearch: String,
    $filter: FacilityFilters, 
    $limit: Int, 
    $offset: Int 
    ) 
    {
      facilities(
        keywordSearch: $keywordSearch,
        filter: $filter, 
        limit: $limit,
        offset: $offset
      ) {
        id
        name
      }
  }
`;
