import gql from "graphql-tag";

export const GET_MASTER_DATA = gql`
  query getMasterData {
    appInit {
      masterData
    }
  }
`;

export const GET_APP_CONFIG_QUERY = gql`
  query getAppInfo {
    appInit {
      company {
        mainContactEmail
        phone
        id
        name
        city
        addressState
        zip
        country
        logoUrl
      }
      companyConfig {
        termUrl
        privacyPolicyUrl
        privacyPolicyContent
        termOfServiceContent
        androidStoreUrl
        universalAppUrl
        instagramUrl
        facebookUrl
        linkedInUrl
        tiktokUrl
      }
      masterData
    }
  }
`;

export const GET_STATES_QUERY = gql`
  query getAppInfo {
    appInit {
      companyReferencesData {
        states {
          countryCode
          countryId
          id
          latitude
          longitude
          name
          stateCode
        }
      }
    }
  }
`;
