const { gql } = require("@apollo/client");

export const GET_CATEGORIES = gql`
  query {
    categories {
      id
      name
      description
      jobsCount
      occupation
      priority
      specialties {
        id
        name
        jobsCount
        maxJobPayAmount
        minJobPayAmount
      }
    }
  }
`;

export const GET_CATEGORIES_V2 = gql`
  query {
    classifyCategories {
      id
      name
      description
      jobsCount
      occupation
      priority
      specialties {
        id
        name
        jobsCount
        maxJobPayAmount
        minJobPayAmount
      }
    }
  }
`;
