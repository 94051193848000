import { gql } from "@apollo/client";
import { SHIFT_REQUEST_FRAGMENT } from "../apollo.fragment";

export const SEARCH_SHIFT_REQUESTS_QUERY = gql`
  query searchShiftRequestQuery(
    $filter: ShiftRequestMatchingFilter
    $sortBy: ShiftManagementSort
    $limit: Int
    $offset: Int
    $showRelatedFacilityIds: Boolean
  ) {
    searchShiftRequests(
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      offset: $offset
      showRelatedFacilityIds: $showRelatedFacilityIds
    ) {
      totalCount
      relatedFacilityIds
      shiftRequests {
        ...shiftRequestFragment
      }
    }
  }
  ${SHIFT_REQUEST_FRAGMENT}
`;

export const GET_SHIFT_REQUEST_DETAIL_QUERY = gql`
  query getShiftRequestDetail($id: String!) {
    shiftRequest(id: $id) {
      ...shiftRequestFragment
    }
  }
  ${SHIFT_REQUEST_FRAGMENT}
`;
