import { gql } from "@apollo/client";
import { RECRUITER_FRAGMENT } from "~/common/apollo/apollo.fragment";

export const VERIFY_RECRUITER_QUERY = gql`
  query verifyRecruiter($token: String!) {
    recruiterToken(token: $token) {
      token
      recruiter {
        ...recruiterFragment
      }
    }
  }

  ${RECRUITER_FRAGMENT}
`;


export const GET_EMPLOYERS_QUERY = gql`
  query getEmployers(
    $companyId: String!, 
    $keywordSearch: String,
    $filter: EmployerFilter, 
    $sortBy: EmployerSort, 
    $limit: Int, 
    $offset: Int
    ) 
    {
      employers(
        companyId: $companyId,
        keywordSearch: $keywordSearch,
        filter: $filter, 
        sortBy: $sortBy, 
        limit: $limit,
        offset: $offset
      ) {
        id
        lastName
        firstName
        avatarUrl
      }
  }
`;


