import { createMuiTheme } from "@material-ui/core";

export const workerTheme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "1px solid #F4F5F4",
      },
    },
    MuiListItem: {
      root: {
        color: "#3C3C3C",
        fontSize: "14px",
        fontWeight: 800,
        letterSpacing: "0.1px",
        "&.Mui-selected": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000146",
        whiteSpace: "nowrap",
        fontWeight: 500,
        "&$focused": {
          color: "#000146",
        },
      },
    },

    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.2px",
        color: "#0E3F6C",
      },
    },
    MuiInputBase: {
      root: {
        color: "#24444E",
        fontWeight: 400,
        fontSize: "14px",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(0.86)",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "transparent !important",
      },
    },
    MuiDialog: {
      container: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    MuiButtonGroup: {
      groupedOutlinedPrimary: {
        "&:hover": {
          borderColor: "#99E1E1",
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        border: "2px solid #99E1E1",
        backgroundColor: "#FFFFFF",
        borderRadius: "100% !important",
        "&:hover": {
          border: "2px solid #99E1E1",
        },
        color: "#04B4D1",
      },
      containedPrimary: {
        color: "#F8F8F8",
        backgroundColor: "#04B4D1",
        boxShadow: "none",
        border: "none",
        "&:hover": {
          backgroundColor: "#9AE8F3 !important",
          boxShadow: "none",
          color: "#000146",
        },
      },

      sizeLarge: {
        height: "36px",
        borderRadius: "44px !important",
      },
      sizeSmall: {
        height: "32px",
        borderRadius: "44px !important",
      },
    },
    MuiTab: {
      root: {
        textTransform: "unset",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#99E1E1",
      },
    },
    MuiSlider: {
      root: {
        color: "#04B4D1",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#04B4D1",
      },
    },
    MuiChip: {
      root: {
        color: "#04B4D1",
        border: "2px solid #04B4D1",
        borderRadius: "48px",
        backgroundColor: "white",
      },
      deleteIcon: {
        color: "red",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "none",
        },
      },
    },
  },
  spacing: 8,
  palette: {
    primary: {
      light: "#E3F8F4",
      main: "#000146",
      secondary: "#729DFF",
      tertiary: "#03606A",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#f8fcfc",
      main: "#04B4D1",
      sub: "#9AE8F3",
    },
    error: {
      main: "#fe7d74",
    },
    text: {
      primary: "#000000",
      secondary: "#03606A",
      tertiary: "#7E7E80",
      disabled: "#AEAEAE",
      blue: "#1C5CDB",
      hint: "#868686",
    },
    background: {
      default: "#ffffff",
      app: {
        dark: "#06163A",
        light: "#FFFFFF",
      },
      element: {
        dark: "#C2DAF0",
        medium: "#DDEFFF",
        light: "#EDF7FF",
        veryLight: "#F6FBFF",
        footerSideBar: "#005395",
      },
    },
    action: {
      active: "#868686",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    content: {
      veryDark: "#06163A",
      dark: "#696969",
      medium: "#D1D1D1",
      light: "#E6E6E6",
      veryLight: "#F8F8F8",
    },
    status: {
      success: {
        dark: "#309102",
        medium: "#3BB700",
        light: "#A5FB7C",
      },
      warning: {
        dark: "#DE662A",
        medium: "#FF9B04",
        light: "#FFD9A1",
      },
      error: {
        dark: "#B9431F",
        medium: "#FF2727",
        light: "#FF9090",
      },
    },
    hyperlink: {
      dark: "#0C4AC2",
      medium: "#2C6EEE",
      light: "#9ABCFF",
    },
    other: {
      dark: "#9F8762",
      medium: "#CEB692",
      light: "#EEE3D2",
    },
  },
  shape: {
    borderRadius: 10,
  },
  borders: [
    "unset",
    "solid 1px #e5e5e5",
    "solid 1px rgba(130, 206, 207, 0.1)",
    "solid 1px #82CECE",
  ],
  typography: {
    fontFamily: ["P22 Mackinac Pro"],
    h6: { fontSize: "1.125rem" },
    h5: { fontSize: "1.1875rem", fontWeight: 500 },
    h4: { fontSize: "1.5rem" },
    h3: { fontSize: "20px", fontWeight: 700 },
    h2: { fontSize: "2rem" },
    h1: { fontSize: "2rem" },
    subtitle1: { fontSize: "16px", fontWeight: 500 },
    subtitle2: { fontSize: "14px", fontWeight: 500 },
    subtitle3: { fontSize: "12px", fontWeight: 500 },
    body1: { fontSize: "14px", fontWeight: "350" },
    body2: { fontSize: "0.675rem" },
    button: { fontSize: "0.9375rem" },
    caption: { fontSize: "0.8125rem" },
  },
  colors: {
    primary: {
      main: "#1F5390",
      sub: "#438BC5",
    },
    secondary: {
      main: "#04B4D1",
      sub: "#42B9A7",
    },
    background: {
      app: {
        dark: "#000000",
        light: "#FFFFFF",
      },
      element: {
        dark: "#C2DAF0",
        medium: "#C8EDEF",
        light: "#EDF7FF",
        veryLight: "#F1FEFF",
        footerSideBar: "#005395",
      },
    },
    content: {
      veryDark: "#333335",
      dark: "#7E7E80",
      medium: "#D1D1D1",
      light: "#E6E6E6",
      veryLight: "#F8F8F8",
    },
    status: {
      success: {
        dark: "#309102",
        medium: "#3BB700",
        light: "#A5FB7C",
      },
      warning: {
        dark: "#DE62A",
        medium: "#FF9B04",
        light: "#FF49F",
      },
      error: {
        dark: "#B9431F",
        medium: "#FF2727",
        light: "#FF9090",
      },
    },
    hyperlink: {
      dark: "#0C4AC2",
      medium: "#2C6EEE",
      light: "#9ABCFF",
    },
    other: {
      dark: "#9F8762",
      medium: "#CEB692",
      light: "#EEE3D2",
    },
  },
});
