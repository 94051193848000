import {
  store,
  computed,
  observable,
  action,
  event,
} from "~/common/mobx.decorator";
import { appStore, routerStore } from "~/stores";
import { HomeSvg, Jobs2Svg, SearchSvg } from "~/components/icons";
import { EVENTS, PATHS } from "~/common/constants";
import { eventBus } from "mobx-event-bus2";
import { isEmpty } from "lodash";

const FEATURE_TYPE = {
  default: "default",
  publicMenu: "publicMenu",
  common: "common",
};

@store()
class CareStore {
  @observable showProfileSidebar;
  @observable customHeader = {};
  @observable worker = {};

  @computed
  get fullAddress() {
    const address = [
      appStore.company?.city,
      appStore.company?.addressState,
      appStore.company?.zip,
      appStore.company?.country,
    ];
    return address.filter((item) => !isEmpty(item)).join(", ");
  }

  @computed
  get email() {
    return appStore.company?.mainContactEmail;
  }

  @computed
  get phone() {
    return appStore.company?.phone;
  }

  @computed
  get privacyPolicyUrl() {
    return appStore.companyConfig?.privacyPolicyUrl;
  }

  @computed
  get termsOfUseUrl() {
    return appStore.companyConfig?.termUrl;
  }

  @computed
  get privacyPolicyContent() {
    return appStore?.companyConfig?.privacyPolicyContent;
  }

  @computed
  get termsOfUseContent() {
    return appStore?.companyConfig?.termOfServiceContent;
  }

  @computed
  get androidStoreUrl() {
    return appStore?.companyConfig?.androidStoreUrl;
  }

  @computed
  get universalAppUrl() {
    return appStore?.companyConfig?.universalAppUrl;
  }

  @computed
  get facebookUrl() {
    return appStore?.companyConfig?.facebookUrl;
  }

  @computed
  get instagramUrl() {
    return appStore?.companyConfig?.instagramUrl;
  }

  @computed
  get linkedInUrl() {
    return appStore?.companyConfig?.linkedInUrl;
  }

  @computed
  get tiktokUrl() {
    return appStore?.companyConfig?.tiktokUrl;
  }

  getFeature = (type) => {
    const publicMenu = [
      {
        code: "worker-home",
        name: "DASHBOARD",
        path: PATHS.worker.home,
        icon: HomeSvg,
        auth: false,
        showAppBar: false,
        showSideBar: false,
        useLayout: true,
        eventId: "dashboard-nav_btn",
      },
      {
        code: "worker-job-results",
        name: "SEARCH_JOBS",
        path: PATHS.worker.search,
        icon: SearchSvg,
        auth: false,
        showAppBar: false,
        showSideBar: false,
        isDisabled: false,
        useLayout: true,
      },
      {
        code: "worker-jobs",
        name: "JOB_DETAILS",
        path: PATHS.worker.jobs,
        icon: Jobs2Svg,
        auth: false,
        showAppBar: false,
        showSideBar: false,
        isDisabled: false,
        useLayout: true,
      },
      {
        code: "worker-signup",
        name: "SIGNUP",
        path: PATHS.common.signup,
        icon: "user",
        auth: false,
        showAppBar: false,
        showSideBar: false,
        useLayout: false,
      },
      {
        code: "worker-wishlist",
        name: "WISHLIST",
        path: PATHS.worker.wishlist,
        icon: Jobs2Svg,
        auth: false,
        showAppBar: false,
        showSideBar: false,
        isDisabled: false,
        useLayout: true,
        bannerUrl: PATHS.banners.first,
      },
      {
        code: "authorization",
        name: "AUTHORIZATION",
        path: PATHS.common.authorization,
        icon: "user",
        auth: false,
        showAppBar: false,
        showSideBar: false,
        useLayout: false,
      },
    ].filter(Boolean);

    const common = [
      // {
      //   code: "common-signin",
      //   name: "SIGNIN",
      //   path: PATHS.common.signin,
      //   icon: "user",
      //   auth: false,
      //   showAppBar: false,
      //   showSideBar: false,
      //   useLayout: false,
      // },
      // {
      //   code: "common-reset",
      //   name: "RESET",
      //   path: PATHS.common.reset,
      //   icon: "user",
      //   auth: false,
      //   showAppBar: false,
      //   showSideBar: false,
      //   isDisabled: false,
      //   useLayout: false,
      // },
      // {
      //   code: "common-change-password",
      //   name: "REFER_TRAVELER",
      //   path: PATHS.common.change_password,
      //   icon: "user",
      //   auth: false,
      //   showAppBar: false,
      //   showSideBar: false,
      //   isDisabled: false,
      //   useLayout: false,
      // },
      // {
      //   code: "common-errors",
      //   name: "ERRORS",
      //   path: PATHS.common.errors,
      //   icon: "user",
      //   auth: false,
      //   showAppBar: false,
      //   showSideBar: false,
      //   isDisabled: false,
      //   useLayout: false,
      // },
    ].filter(Boolean);

    let result = null;
    switch (type) {
      case FEATURE_TYPE.publicMenu:
        result = publicMenu;
        break;
      case FEATURE_TYPE.common:
        result = common;
        break;
      default:
        result = [];
        break;
    }
    return result;
  };

  @computed
  get features() {
    const publicMenu = this.getFeature(FEATURE_TYPE.publicMenu);
    return publicMenu;
  }

  @computed
  get allFeatures() {
    const common = this.getFeature(FEATURE_TYPE.common);
    const allFeature = [...this.features, ...common];

    return allFeature?.find((feature) => feature.path === routerStore.basePath);
  }

  @computed
  get activeFeature() {
    return this.features?.find(
      (feature) => feature.path === routerStore.basePath
    );
  }

  @computed
  get code() {
    return this.activeFeature?.code;
  }

  @computed
  get name() {
    return this.activeFeature?.name || "";
  }

  @computed
  get useLayout() {
    return this.activeFeature?.useLayout;
  }

  @computed
  get sidebarMenu() {
    return this.features?.filter((feature) => feature.showSideBar);
  }

  @computed
  get appbarMenu() {
    return this.features?.filter((feature) => feature.showAppBar);
  }

  @computed
  get compamyInfo() {
    return this.company;
  }

  @action
  toogleProfileSidebar = async () => {
    this.showProfileSidebar = !this.showProfileSidebar;
  };

  @action
  logout = async () => {
    this.showProfileSidebar = false;

    // const response = await apolloClient.mutate({
    //   mutation: SIGNOUT_RECRUITER_MUTATION,
    // })

    // const success = response.data?.signOutRecruiter
    // if(success) notifyStore.success("$MESSAGES.SUCCESSFUL")

    eventBus.post(EVENTS.authStore.logout);
    routerStore.goPage(PATHS.common.signin);
  };

  @action
  handleClickChangePassword = async () => {
    this.showProfileSidebar = false;
    await routerStore.goPage(PATHS.common.change_password);
  };

  @event(EVENTS.careStore.updateHeader)
  async onUpdateHeader({ payload }) {
    this.customHeader = payload;
  }
}

export const careStore = new CareStore();
