import gql from 'graphql-tag'
import { JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const CREATE_SAVE_MUTATION = gql`
	mutation CreateSavedFilter($savedFilter: SavedFilterArgument, $filterConditions: JobFilter) {
		createSavedFilter(savedFilter: $savedFilter, filterConditions: $filterConditions) {
			id
			name
			filterCondition {
				exclusiveJob
				externalId
				locationCities
				locationStates
				maxWage
				minMatchingPercentage
				jobTypes
				minWage
				nearBy
				id
				profession
				shifts
				skillName
			}

			alertChannel
			enableAlert
			frequency
			lastAlertedAt
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`
export const UPDATE_SAVE_MUTATION = gql`
	mutation UpdateSavedFilter($savedFilter: SavedFilterArgument, $filterConditions: JobFilter) {
		updateSavedFilter(savedFilter: $savedFilter, filterConditions: $filterConditions) {
			id
			name
			filterCondition {
				exclusiveJob
				externalId
				locationCities
				id
				locationStates
				jobTypes
				maxWage
				minMatchingPercentage
				jobTypes
				minWage
				nearBy
				profession
				shifts
				skillName
			}
			alertChannel
			enableAlert
			frequency
			lastAlertedAt
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`

export const DELETE_SAVE_MUTATION = gql`
	mutation DeleteSavedFilter($savedFilter: SavedFilterArgument) {
		destroySavedFilter(savedFilter: $savedFilter) {
			id
			success
		}
	}
`

export const SYNC_ANONYMOUS_WORKER_DATA = gql`
	mutation SyncAnonymousWorkerData(
		$savedFiltersAttributes: [SavedFilterArgument!]
		$workerViewedJobsAttributes: [WorkerViewedJobArgument!]
		$bookmarkJobsAttributes: [BookmarkJobArgument!]
	) {
		syncAnonymousWorkerData(
			bookmarkJobsAttributes: $bookmarkJobsAttributes
			savedFiltersAttributes: $savedFiltersAttributes
			workerViewedJobsAttributes: $workerViewedJobsAttributes
		) {
			success
		}
	}
`
export const GET_DISCOVER_JOB_BOOKMARK = gql`
	query getDiscoverJobBookMark {
		discoversV2 {
			bookmarkedJobs
		}
	}
`

export const GET_LIST_BOOK_MARK = gql`
	query getListBookmarkJobs($after: String, $before: String, $last: Int, $first: Int) {
		bookmarkedJobs(after: $after, before: $before, last: $last, first: $first) {
			nodes {
				...jobFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}
	${JOB_FRAGMENT}
`

export const BOOKMARK_A_JOB_MUTATION = gql`
	mutation BookmarkAJob($jobId: String!, $action: BookmarkActionEnum!) {
		bookmarkAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`
