export const PROVIDER_NAME = {
  facebook: "facebook",
  google: "google",
  linkedin: "linkedin",
  apple: "apple",
};

export const PROVIDER_SOCIAL_CAPATILIZE = {
  facebook: "Facebook",
  google: "Google",
  linkedin: "LinkedIn",
  apple: "Apple",
};

export const SOCIAL_WORKER_INIT = [
  {
    providerName: "google",
    value: false,
    title: "Google",
  },
  {
    providerName: "facebook",
    value: false,
    title: "Facebook",
  },
  {
    providerName: "linkedin",
    value: false,
    title: "Linkedin",
  },
];

export const AUTHENTIACTION_ALREADY_CONNECT =
  "authentication_already_connect_to_user_error";
