import { css } from "styled-components";
import { spacing } from "@material-ui/system";

export const buttonStyle = css`
  border-radius: 5px;
  ${spacing};
  @media only screen and (max-width: 450px) {
    font-size: 10px;
  }
`;
