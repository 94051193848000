import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "@opus/web.core.hooks.use-translation";
import { Button } from "../button";
import { notifyDialogStyle } from "./notify.style";

export const NotifyDialog = ({
  title,
  hideTitle,
  okText,
  cancelText,
  onCancel,
  onOk,
  okProps,
  cancelProps,
  children,
  hideOk = false,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog {...props} css={notifyDialogStyle}>
      <Box margin={2}>
        {!hideTitle && (
          <Box marginBottom={1} className="header">
            <Typography variant="subtitle1">{t(title)}</Typography>
          </Box>
        )}

        <Box marginTop={3} marginBottom={3} className="content-notify">
          {children}
        </Box>
        <Grid container spacing={2}>
          {onCancel && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onCancel}
                {...cancelProps}
              >
                {t(cancelText)}
              </Button>
            </Grid>
          )}
          <Grid item xs={onCancel ? 6 : 12}>
            {!hideOk && <Button key={okProps?.disabled} onClick={onOk} {...okProps}>
              {t(okText)}
            </Button>}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

NotifyDialog.defaultProps = {
  open: true,
  okText: "OK",
  cancelText: "CANCEL",
  title: "NOTIFICATION",
};
