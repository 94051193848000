export const PATHS = {
  // COMMON
  common: {
    home: "/",
    reset: "/reset",
    signin: "/signin",
    errors: "/errors",
    change_password: "/change_password",
    api: "/api",
    graphql: "/graphql",
    signup: "/signup",
    tracking: "/tracking",
    link_options: '/link_options',
    authorization: "/authorization",
  },
  // Worker Portal
  worker: {
    home: "/home",
    search: "/search",
    jobs: "/jobs",
    profile: "/profile",
    wishlist: "/wishlist"
  },
  banners: {
		first: '/assets/common/banner-1.png',
		second: '/assets/common/banner-2.png',
		third: '/assets/common/banner-3.png',
		four: '/assets/common/banner-4.png',
	},
  auth: {
		linkedin: '/linkedin',
	},
};
