import { flatMap, isEmpty, maxBy, minBy } from "lodash";
import {
  action,
  computed,
  observable,
  persist,
  store,
} from "~/common/mobx.decorator";
import {
  apolloClient,
  GET_STATES_QUERY,
  LIKE_DISLIKE_JOB_MUTATION,
} from "~/common/apollo";
import { appStore, authStore, notifyStore } from "~/stores";
import { redirectEventTrackingBookMark } from "~/common/helpers/bookMark.helper";
import {
  GET_CATEGORIES,
  GET_CATEGORIES_V2,
} from "~/common/apollo/queries/category.query";
import { GET_FACILITYS_QUERY } from "~/common/apollo/queries/facility.query";

const DEFAULT_DISCIPLINE = "RN";

@store()
class MasterStore {
  @persist @observable licenseStates;
  @persist @observable specialtyOptions = [];
  @persist @observable classifySpecialtyOptions = [];
  @persist @observable professionOptions = [];
  @persist @observable facilities = [];
  @persist @observable masterData = {};
  @observable facilitySearch = "";

  @computed
  get minMaxWageBySpecialty() {
    const allSpecialties = flatMap(this.specialtyOptions, "child");
    const minWage =
      allSpecialties?.length > 0
        ? minBy(allSpecialties, "minJobPayAmount").minJobPayAmount
        : 0;
    const maxWage =
      allSpecialties?.length > 0
        ? maxBy(allSpecialties, "maxJobPayAmount").maxJobPayAmount
        : 20000;
    return { minWage, maxWage };
  }

  @computed
  get frequencyMasterData() {
    const frequency = appStore.masterData?.save_filter?.frequency;
    if (isEmpty(frequency)) {
      return [];
    }

    return Object.entries(frequency).map(([key, value]) => ({
      key,
      value: value,
      label: value,
    }));
  }

  @computed
  get shiftTypes() {
    const shiftTypes = appStore.masterData?.save_filter?.shift_type;
    if (isEmpty(shiftTypes)) {
      return [];
    }

    return Object.entries(shiftTypes).map(([key, value]) => ({
      key,
      value: key,
      label: value,
    }));
  }

  minMaxWageByDiscipline = (discipline) => {
    if (discipline) {
      const allSpecialties = discipline?.specialties;
      const minWage =
        allSpecialties?.length > 0
          ? minBy(allSpecialties, "minJobPayAmount").minJobPayAmount
          : 0;
      const maxWage =
        allSpecialties?.length > 0
          ? maxBy(allSpecialties, "maxJobPayAmount").maxJobPayAmount
          : 20000;
      return { minWage, maxWage };
    }
    return null;
  };

  @action
  fetchCategories = async (force = false) => {
    // if (force || !isEmpty(this.categories)) {
    //   return;
    // }

    const response = await apolloClient.query({ query: GET_CATEGORIES });
    this.categories = response.data?.categories;

    this.professionOptions = response.data?.categories?.map(
      ({ id, name, priority, specialties }) => ({
        label: name,
        value: id,
        priority,
        specialties,
      })
    );

    this.specialtyOptions = response.data?.categories
      ?.map(({ id, name, jobsCount, specialties }) => ({
        label: name,
        value: id,
        jobsCount: jobsCount,
        child: specialties?.map(
          ({ id, name, jobsCount, minJobPayAmount, maxJobPayAmount }) => {
            return {
              label: name,
              value: id,
              jobsCount,
              minJobPayAmount,
              maxJobPayAmount,
            };
          }
        ),
      }))
      .filter((item) => item.label === DEFAULT_DISCIPLINE);
  };

  @action
  fetchClassifyCategories = async (force = false) => {
    // if (force || !isEmpty(this.categories)) {
    //   return;
    // }

    const response = await apolloClient.query({ query: GET_CATEGORIES_V2 });
    this.classifyCategories = response.data?.classifyCategories;

    this.classifySpecialtyOptions =
      response.data?.classifyCategories?.map(
        ({ id, name, jobsCount, specialties }) => ({
          label: name,
          value: id,
          jobsCount: jobsCount,
          child: specialties?.map(
            ({ id, name, jobsCount, minJobPayAmount, maxJobPayAmount }) => {
              return {
                label: name,
                value: id,
                jobsCount,
                minJobPayAmount,
                maxJobPayAmount,
              };
            }
          ),
        })
      ) || [];
  };

  @action
  fetchFacilities = async (facilityIds = null) => {
    if (isEmpty(facilityIds) || facilityIds?.length === 0) {
      this.facilities = [];
      return;
    }
    const response = await apolloClient.query({
      query: GET_FACILITYS_QUERY,
      variables: {
        keywordSearch: this.facilitySearch,
        filter: {
          facilityIds,
        },
        limit: 50,
        offset: 0,
      },
    });
    this.facilities = response.data?.facilities?.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    }));
  };

  @action
  fetchLoadmoreFacilities = async (facilityIds = null) => {
    if (isEmpty(facilityIds)) {
      return;
    }
    const response = await apolloClient.query({
      query: GET_FACILITYS_QUERY,
      variables: {
        keywordSearch: this.facilitySearch,
        filter: {
          facilityIds,
        },
        limit: 50,
        offset: this.facilities.length,
      },
    });
    const facilities = response.data?.facilities?.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    }));

    this.facilities = [...this.facilities, ...facilities];
  };

  @action
  onFavoriteJob = async (values) => {
    const { jobId, action } = values?.variables;
    try {
      const response = await apolloClient.mutate({
        mutation: LIKE_DISLIKE_JOB_MUTATION,
        variables: {
          jobId: jobId,
          action: action,
        },
      });
      notifyStore.success("$MESSAGES.SUCCESSFUL");
      return response?.data?.likeOrDislikeAJob;
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  onTrackingFavorite = async (jobId, flag, finalizeData) => {
    if (finalizeData?.type) {
      if (finalizeData?.status === "EVENT_ORDER_CITY") {
        await redirectEventTrackingBookMark(finalizeData.type, {
          job_id: jobId,
          is_bookmarked: !flag,
          worker_email: authStore.workerEmail,
          order_number: finalizeData.order,
          city_id: finalizeData.cityId,
        });
      } else if (finalizeData?.status === "EVENT_ORDER") {
        await redirectEventTrackingBookMark(finalizeData.type, {
          job_id: jobId,
          is_bookmarked: !flag,
          worker_email: authStore.workerEmail,
          order_number: finalizeData.order,
        });
      } else {
        await redirectEventTrackingBookMark(finalizeData.type, {
          job_id: jobId,
          is_bookmarked: !flag,
          worker_email: authStore.workerEmail,
        });
      }
    }
  };

  @action
  fetchStates = async (force = false) => {
    // if (!force && !isEmpty(this.licenseStates)) {
    // 	return
    // }

    const { data } = await apolloClient.query({ query: GET_STATES_QUERY });
    this.licenseStates = data?.appInit?.companyReferencesData?.states;
  };

  @action
  setFacilitySearch = (value) => {
    this.facilitySearch = value;
  };
}

export const masterStore = new MasterStore();
