import axios from "axios";
import { nanoid } from "nanoid";
import { apolloClient, GET_APP_CONFIG_QUERY } from "~/common/apollo";
import { captureException } from "~/common/helpers";
import {
  observable,
  action,
  store,
  computed,
  storage,
  persist,
} from "~/common/mobx.decorator";

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}`;
axios.defaults.headers["APP_ID"] = process.env.REACT_APP_PROXY_API_APP_ID;
axios.defaults.headers["APP_SECRET"] =
  process.env.REACT_APP_PROXY_API_APP_SECRET;

axios.interceptors.response.use(undefined, (error) => {
  captureException("Axios Client", error);
  return Promise.reject(error);
});

@store({ persist: true })
class AppStore {
  constructor() {
    storage.ready(() => {
      this.ready = true;
      if (!this._uniqueId) {
        this._uniqueId = nanoid();
      }
    });
  }

  @persist @observable _uniqueId;
  @observable config = null;
  @observable ready = false;
  @observable meta_domain;
  @observable companyConfig = {};
  @observable masterData = null;
  @observable company = {};

  @computed
  get id() {
    // Company Id
    return this.company?.id || process.env.REACT_APP_COMPANY_ID;
  }

  @computed
  get name() {
    // Company Name
    return this.company?.name || "My Health Gig";
  }

  @computed
  get logo() {
    return (
      this.company?.logoUrl ||
      `${process.env.PUBLIC_URL}/assets/care/logo192.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`
    );
  }

  @computed
  get authImage() {
    return `${process.env.PUBLIC_URL}/assets/care/auth.png`;
  }

  @action
  init = async () => {
    await this.fetchCompanyInfo();
    await this.fetchFEConfig();
    // await this.fetchAppConfig();

    return new Promise((resolve) => {
      // wait 1s to make sure all resource loaded
      setTimeout(async () => {
        resolve();
      }, 1000);
    });
  };

  fetchAppConfig = async () => {
    const response = await axios.get(`/company_configs/${this.id}`);
    this.config = response?.data;
    this.meta_domain = response?.data?.companyConfigs?.ssrUrl;
  };

  fetchFEConfig = async () => {
    const response = await fetch("/app-config.json");
    const data = await response.json();
    return data;
  };

  fetchCompanyInfo = async () => {
    const { data } = await apolloClient.query({ query: GET_APP_CONFIG_QUERY });
    this.companyConfig = data?.appInit?.companyConfig;
    this.masterData = data?.appInit?.masterData;
    this.company = data?.appInit?.company;
  };
}

export const appStore = new AppStore();
