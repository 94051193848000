import { gql } from "@apollo/client";
import {
  WORKER_FRAGMENT,
  WORKER_CERTIFICATION_FRAGMENT,
  WORKED_TIMESHEET_FRAGMENT,
  IMAGE_FILE_FRAGMENT,
  SALESFORCE_INTEGRATION_FRAGMENT,
  WORKER_CREDENTIAL_FRAGMENT,
  WORKING_AUTHORIZATION_FRAGMENT,
  WORK_EXPERIENCE_FRAGMENT,
  EMERGENCY_CONTACT_FRAGMENT,
  WORK_EXPERIENCE_OVERVIEW_FRAMENT,
  WORKER_REFERENCE_FRAGMENT,
  JOB_FRAGMENT,
  WORKER_ADDRESS_FRAGMENT,
} from "../apollo.fragment";

export const VERIFY_WORKER_QUERY = gql`
  query verifyWorker($token: String!) {
    workerToken(token: $token) {
      token
      worker {
        ...workerFragment
        recruiter {
          id
          name
          phone
          email
          title
        }
        company {
          salesforceIntegration {
            ...salesforceIntegrationFragment
          }
        }
      }
    }
  }

  ${WORKER_FRAGMENT}
  ${SALESFORCE_INTEGRATION_FRAGMENT}
`;

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
  query getWorkerDetailTimeQuery($id: String!) {
    worker(id: $id) {
      ...workerFragment
      company {
        salesforceIntegration {
          ...salesforceIntegrationFragment
        }
      }
      workedTimesheets {
        ...workedTimesheetFragment
        timesheetImages {
          imageUrls(size: ["100x100", "200x200"])
          ...imageFileFragment
        }
      }
    }
  }

  ${WORKER_FRAGMENT}
  ${SALESFORCE_INTEGRATION_FRAGMENT}
  ${WORKED_TIMESHEET_FRAGMENT}
  ${IMAGE_FILE_FRAGMENT}
`;

export const GET_CREDENTIALING_URL_QUERY = gql`
  query getWorkerDetailCredentialingQuery($id: String!) {
    worker(id: $id) {
      id
      credentialingUrl
    }
  }
`;

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
  query getWorkerCertificationsQuery($id: String!) {
    workerCertifications(workerId: $id) {
      ...workerCertificationFragment
      licenseImages {
        ...imageFileFragment
      }
    }
  }

  ${WORKER_CERTIFICATION_FRAGMENT}
  ${IMAGE_FILE_FRAGMENT}
`;

export const GET_WORKER_CREDENTIALS_QUERY = gql`
  query getWorkerCredentialsQuery(
    $filter: WorkerCredentialStatusEnum
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    workerCredentials(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        ...workerCredentialFragment
      }
    }
  }

  ${WORKER_CREDENTIAL_FRAGMENT}
`;
export const GET_WORKER_SOCIAL_QUERY = gql`
  query getWorkerSocialQuery {
    workerSocialAuthentications {
      providerName
      id
      providerUuid
      email
    }
  }
`;

export const GET_WORKER_ASSIGNMENT_QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_WORKER_DETAIL_QUERY = gql`
	query getWorkerDetailQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workExperienceOverview {
				...workExperienceOverview
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			emergencyContact {
				...emergencyContactFragment
			}
			workerAddress {
				...workerAddressFragment
			}
			supportDocuments {
				id
				documentType
				document {
					id
					contentType
					fileUrl
					filename
					thumbnails
					blobId
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
			workerReferences {
				...workerReferenceFragment
			}
			workingPreferredLocations {
				id
				preferredWorkingCity
				preferredWorkingState
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKER_REFERENCE_FRAGMENT}
`