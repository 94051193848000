export const EVENTS = {
  authStore: {
    logout: "authStore/logout",
  },
  notifyStore: {
    fireError: "notifyStore/fireError",
  },
  careStore: {
    updateHeader: "careStore/updateHeader",
  },
  careHomeStore: {
    updateActivationOverall: "careHomeStore/updateActivationOverall",
  },
};

export const ACTIONS = {
  appStore: {
    init: "appStore/init",
  },

  authStore: {
    changeToken: "authStore/changeToken",
  },

  careActivationStore: {
    fetchOnboardingOverview: "careActivationStore/fetchOnboardingOverview",
  },

  careTimeCardsStore: {
    fetchTimeCards: "careTimeCardsStore/fetchTimeCards",
  },

  licenseStepStore: {
    handleSubmitLicense: "licenseStepStore/handleSubmitLicense",
  },

  experienceStepStore: {
    handleSubmitExperiences: "experienceStepStore/handleSubmitExperiences",
  },

  referenceStepStore: {
    handleSubmitReferences: "referenceStepStore/handleSubmitReferences",
  },
};
