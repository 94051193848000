import React from "react";
export const FEATURES = {
  // HEALTHGIG FEATURES
  "worker-home": React.lazy(() => import("~/features/worker-home")),
  "worker-job-results": React.lazy(() => import("~/features/worker-job-results")),
  "worker-jobs": React.lazy(() => import("~/features/worker-jobs")),
  "worker-signup": React.lazy(() => import("~/features/worker-signup")),
  "worker-wishlist": React.lazy(() => import("~/features/worker-wishlist")),

  // COMMON FEATURES
  // "common-signin": React.lazy(() => import("~/features/common-signin")),
  // "common-errors": React.lazy(() => import("~/features/common-errors")),
  // "common-reset": React.lazy(() => import("~/features/common-reset")),
  // "common-change-password": React.lazy(() =>
  //   import("~/features/common-change-password")
  // ),
  "authorization": React.lazy(() => import('~/features/common-authorization')),
  'link-options': React.lazy(() => import('~/features/common-link-options')),
};
