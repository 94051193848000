import axios from "axios";
import { appStore } from "~/stores";
import moment from "moment";
import { generateUUID } from "../helpers/uid.helper";
import { isEmpty } from "lodash";

export const createAxios = (headers) => {
  return axios.create({
    baseURL: process.env.REACT_APP_TRACKING_API_URL,
    headers: {
      "x-api-key": process.env.REACT_APP_TRACKING_API_KEY,
      ...headers,
    },
    method: "PUT",
    timeout: 30000,
  });
};

const records = async (dataValue) => {
  try {
    let anonymousId = window.localStorage.getItem("anonymousId");
    if (isEmpty(anonymousId)) {
      anonymousId = generateUUID();
      localStorage.setItem("anonymousId", anonymousId);
    }

    const currentTime = moment();
    const formattedTime = currentTime.format("YYYY-MM-DD HH:mm:ss Z");
    const data = {
      ...dataValue?.name?.payload,
      ui_key: dataValue?.name?.uiKey,
      component_name: dataValue?.name?.componentName,
      location: null,
      created_at: formattedTime,
      deleted_at: null,
      user_properties: {
        id: null,
        company_id: appStore.id,
      },
      anonymous_id: anonymousId,
    };

    const payload = {
      timestamp: +Math.floor(Date.now() / 1000),
      company_id: appStore.id,
      user_id: "",
      is_anonymous: true,
      is_mobile: false,
      name: dataValue?.name?.type,
      data,
    };

    const trackingData = {
      is_anonymous: true,
      Records: [payload],
    };

    return await createAxios().put("/anonymous", trackingData);
  } catch (error) {
    return null;
  }
};

export const httpRequestTracking = {
  record: records,
};
