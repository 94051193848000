import { eventClient, events } from "@opus/web.core.lib.event-tracking";
import axios from "axios";
import { apolloClient, VERIFY_EXISTED_EMAIL } from "~/common/apollo";
import { EVENTS, ONE_DAY, PATHS } from "~/common/constants";
import { USER_TITLE } from "~/common/constants/users.constant";
import { captureException, RECAPTCHA_CLIENT_KEY } from "~/common/helpers";
import {
  store,
  computed,
  action,
  observable,
  persist,
  event,
} from "~/common/mobx.decorator";

const matchAnonymousEvents = async () => {
  // ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
  try {
    const anonymousEvents = await window.host.getUserEvents();
    const anonymousId = await window.host.getId();

    const searchEvents = anonymousEvents
      .filter((event) => event.name === "ANONYMOUS_SEARCH")
      .map(
        (event) =>
          new events.AnonymousSearchSuccessEvent({
            ...event.data,
            createdAt: event.createdAt?.seconds * 1000 || new Date().getTime(),
            metadata: {
              ip: event.ip,
              user_agent: event.userAgent,
              anonymous_id: anonymousId,
            },
          })
      );

    searchEvents.forEach((event) => eventClient.logEvent(event));
    await window.host.archiveUserEvents();
    // IN CASE: You want to clean up all archived events
    // Please call: window.host.cleanUserEvents()
  } catch (error) {
    console.debug(error);
  }
};

class ProfileItem {
  @persist @observable id;
  @persist @observable firstName;
  @persist @observable lastName;
  @persist @observable workAuthorized;
  @observable isAnonymousLogin = false
}

@store({ persist: true })
class AuthStore {
  @persist @observable token = "";
  @persist @observable expiresAt = 0;
  @persist("object", ProfileItem) @observable profile;
  @observable showGuidanceDialog = false

  @observable currentJob = null;

  @action
  changeActiveTabIndex = (tabIndex) => {
    if (this.activeTabIndex === tabIndex) {
      return;
    }

    this.prevActiveTabIndex = -1;
    this.activeTabIndex = tabIndex;
  };

  @computed
  get worker() {
    return this.profile?.worker;
  }

  @computed
  get authorization() {
    return !this.token || Date.now() > this.expiresAt ? "" : this.token;
  }

  @computed
  get features() {
    return ["worker-home", "common-change-password"];
  }

  @computed
  get id() {
    return this.authorization && this.profile?.id;
  }

  @computed
  get firstName() {
    return this.profile?.firstName;
  }

  @computed
  get lastName() {
    return this.profile?.lastName;
  }

  @computed
  get fullName() {
    return [this.firstName, this.lastName].join(" ").trim();
  }

  @computed
  get activated() {
    return this.isAdminLoggedIn || this.isManagerLoggedIn || this.isOnsiteManagerLoggedIn; //TODO: CHANGE LOGIC FOR ROLE
  }

  @computed
  get isAdminLoggedIn() {
    return this.profile?.title === USER_TITLE.manager; 
  }

  @computed
  get isManagerLoggedIn() {
    return this.profile?.title === USER_TITLE.admin;
  }

  @computed
  get isOnsiteManagerLoggedIn() {
    return this.profile?.title === USER_TITLE.onsite_manager;
  }

  @computed
	get isAnonymous() {
		return this.isAnonymousLogin || this.authorization === ''
	}

  @action
  changeProfile = async (profile) => {
    this.profile = profile;
    if (profile) {
      eventClient.setUserId(profile.id);
      eventClient.setUserProperties({
        id: profile.id,
        email: profile.email,
        first_name: profile?.firstName,
        last_name: profile.lastName,
        company_id: process.env.REACT_APP_COMPANY_ID,
      });

      // ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
      await matchAnonymousEvents();
    }

    // notifyStore.signal.push(() => {
    // 	notifyStore.signal.sendTags(profile)
    // 	notifyStore.signal.setExternalUserId(profile?.id)
    //})

    // Update LiveChat Info
    // if (window.LC_API && typeof window.LC_API.set_visitor_name === "function") {
    //   window.LC_API.set_visitor_name(
    //     [profile?.firstName, profile?.lastName].join(" ").trim()
    //   );
    //   window.LC_API.set_visitor_email(profile?.email);
    // }
  };

  @action
	handleAuthAnonymous(value) {
		return (this.isAnonymousLogin = value)
	}

  @action
  changeToken = async (token = "", duration = ONE_DAY * 30) => {
    this.token = token;
    this.expiresAt = token ? Date.now() + duration : -1;

    if (token) {
      axios.defaults.headers.common["Auth-Token"] = token;
    } else {
      delete axios.defaults.headers.common["Auth-Token"];
    }
  };


  @event(EVENTS.authStore.logout)
  logout() {
    this.changeToken("");
    eventClient.setUserId(null);
    eventClient.setUserProperties(null);
    authStore.handleAuthAnonymous(false)
  }

  @action
	resetPersist = () => {
		this.token = ''
		this.expiresAt = 0
		this.profile = null
		this.isExistAccount = false
		this.notificationChannel = ''
		this.currentJob = null
		this.phoneVerifiedAt = null
	}

  @action
	verifyExistEmail = async (variables, { setErrors }, history) => {
		authStore.resetPersist()
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})
					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { verifyExistedEmail },
					} = await apolloClient.mutate({
						mutation: VERIFY_EXISTED_EMAIL,
						variables: updatedVariables,
					})

					const { existed } = verifyExistedEmail
					this.isExistAccount = existed
          if(existed) {
            this.showGuidanceDialog = true
          }else{
            this.showGuidanceDialog = false
            await history.push(PATHS.common.signup, {
              email: variables.email,
            }) 
          }
					// existed
					// 	? await history.push(PATHS.common.signin, {
					// 			email: variables.email,
					// 	  })
					// 	: await history.push(PATHS.worker.signup, {
					// 			email: variables.email,
					// 	  })

					resolve()
				} catch (error) {
					captureException('Signin', error)
					setErrors({ password: error.message })
					reject(error.message)
				}
			})
		})
	}

  @action 
  setGuidanceDialog = async (value) => {
    this.showGuidanceDialog = value
  }
}

export const authStore = new AuthStore();
