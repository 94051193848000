import { isEmpty, isNil, isString, omit } from "lodash";
import { workerFindJobStore } from "~/features/worker-job-results/worker-job-results.store";

export const toSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const mappingJobSearchData = (lastSaveSearch) => {
  const lastSaveSearchFilter = {};
  if (lastSaveSearch) {
    const {
      exclusive_job,
      external_id,
      keyword,
      max_wage,
      min_wage,
      near_by,
      min_matching_percentage,
      radius,
      shifts,
      skill_name,
      profession,
      posted_in_days,
      job_types,
      location_states,
      location_cities,
    } = lastSaveSearch;
    lastSaveSearchFilter.exclusiveJob = exclusive_job;

    if (external_id) {
      lastSaveSearchFilter.externalId = external_id;
    }
    if (keyword) {
      lastSaveSearchFilter.keyword = keyword;
    }
    if (max_wage) {
      lastSaveSearchFilter.maxWage = max_wage;
    }
    if (near_by) {
      lastSaveSearchFilter.nearBy = near_by;
    }
    if (min_wage) {
      lastSaveSearchFilter.minWage = min_wage;
    }

    if (posted_in_days) {
      lastSaveSearchFilter.postedInDays = posted_in_days;
    }
    if (profession) {
      lastSaveSearchFilter.profession = profession;
    }

    if (location_cities) {
      lastSaveSearchFilter.locationCities = location_cities;
    }

    if (location_states) {
      lastSaveSearchFilter.locationStates = location_states;
    }
    if (job_types) {
      lastSaveSearchFilter.jobTypes = job_types;
    }

    if (min_matching_percentage) {
      lastSaveSearchFilter.minMatchingPercentage = min_matching_percentage;
    }

    if (radius) {
      lastSaveSearchFilter.radius = radius;
      lastSaveSearchFilter.initRadius = radius;
    }

    if (shifts) {
      lastSaveSearchFilter.shifts = shifts;
    }

    if (skill_name) {
      lastSaveSearchFilter.skillName = skill_name;
    }
  }

  return lastSaveSearchFilter;
};

export const mappingFilterJobSearch = (searchData) => {
  let searchDataFilter = {};
  if (searchData) {
    const {
      nearBy,
      shiftTypes,
      minWage,
      facilityIds,
      specialty,
      startTimeBetween,
      discipline,
      locationStates,
      locationCities,
    } = searchData;

    let location = searchData?.location;

    if (shiftTypes) {
      searchDataFilter.shiftTypes = shiftTypes;
    }

    if (facilityIds) {
      searchDataFilter.facilityIds = facilityIds;
    }

    if (discipline) {
      searchDataFilter.profession = discipline?.value;
      if (specialty?.length <= 0) {
        searchDataFilter.specialtyIds = discipline?.specialties?.map(
          (item) => item.id
        );
      }
    }

    if (specialty?.length > 0) {
      searchDataFilter.specialtyIds = specialty.map((item) => item.value);
    }

    if (locationStates) {
      searchDataFilter.locationStates = locationStates;
    }

    if (locationCities) {
      searchDataFilter.locationCities = locationCities;
    }

    if (
      isEmpty(location) &&
      location === undefined &&
      workerFindJobStore.locationInitial?.length > 0
    ) {
      location = workerFindJobStore.locationInitial;
    }

    if (!isEmpty(location)) {
      const cities = location
        .filter((location) => location.type === "locationCity")
        .map((item) => item.value);
      const states = location
        .filter((location) => location.type === "locationState")
        .map((item) => item.value);

      searchDataFilter.locationCities = cities;
      searchDataFilter.locationStates = states;

      if (cities.length === 1 || states.length > 0) {
        const radiusValue =
          isNil(location) ||
          location?.every((item) => item.type !== "locationCity");
        if (!radiusValue) {
          if (nearBy) {
            searchDataFilter.nearBy = {
              latitude: null,
              longitude: null,
              radius: nearBy?.radius || 50,
            };
          }
        }
      }
    }
    // else {
    // const storedLatitude = localStorage.getItem("browserLatitude");
    // const storedLongitude = localStorage.getItem("browserLongitude");
    // searchDataFilter.nearBy = {
    //   latitude: storedLatitude,
    //   longitude: storedLongitude,
    //   radius: nearBy?.radius || 50,
    // };
    // }

    if (minWage) {
      searchDataFilter.minWage = minWage;
    }

    if (!isEmpty(startTimeBetween)) {
      const from = startTimeBetween?.start;
      const to = startTimeBetween?.end?.endOf("day");
      const utcFromUnix = from.unix() * 1000;
      const utcToUnix = to.unix() * 1000;
      searchDataFilter.startTimeBetween = {
        from: utcFromUnix.toString(),
        to: utcToUnix.toString(),
      };
    } else {
      searchDataFilter = omit(searchDataFilter, "from");
      searchDataFilter = omit(searchDataFilter, "to");
    }
  }

  return searchDataFilter;
};

export const convertStateToArray = (data) => {
  return {
    ...data,
    locationState: isString(data?.locationStates)
      ? data?.locationStates?.split(",")
      : data?.locationStates,
  };
};

export const convertStateToString = (data) => {
  return {
    ...data,
    locationStates: data?.locationStates,
  };
};

export const mappingSkillName = (data) => {
  const skillNameLength = data?.skillName?.length;
  const profession = data?.profession;

  return (!!profession && skillNameLength === 0) ||
    (!!profession && !skillNameLength)
    ? {
        ...data,
        skillName: workerFindJobStore
          .disciplineOptions(profession)
          .map((item) => item?.value),
      }
    : data;
};

export const convertCommaSeparatedValuesToArray = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "string" && obj[key].includes(",")) {
      obj[key] = obj[key].split(",");
    }
  }
  return obj;
};

export const convertToCorrectTypes = async (object) => {
  const allowedKeys = [
    "specialty",
    "shifts",
    "locationCity",
    "locationState",
    "profession",
  ];
  const values = Object.keys(object)
    .filter((key) => allowedKeys.includes(key))
    .reduce((filteredObj, key) => {
      filteredObj[key] = object[key];
      return filteredObj;
    }, {});

  if (values.hasOwnProperty("locationState")) {
    const locationStates = values?.locationState?.split(",");
    if (locationStates.length > 1) {
      values.locationStates = locationStates;
      delete values.locationCity;
    } else {
      if (values.hasOwnProperty("locationCity")) {
        values.locationCities = [
          `${values?.locationCity}, ${values?.locationState}`,
        ];
      }
    }
  }

  values.shifts = values?.shifts?.split(",");
  if (values.hasOwnProperty("specialty")) {
    values.skillName = values?.specialty?.split(",");
    delete values.specialty;
  }

  return values;
};

export const mappingObjectToArrayField = (obj) => {
  return Object.entries(obj).map(([key, value]) => {
    return {
      key,
      value,
    };
  });
};

export function convertKeysToSnakeCase(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return as is if obj is not an object or is null
  }

  if (Array.isArray(obj)) {
    // If obj is an array, recursively convert keys for each item in the array
    return obj.map((item) => convertKeysToSnakeCase(item));
  }

  const snakeCaseObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Convert key to snake case
      const snakeCaseKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
      // Recursively convert keys for nested objects
      snakeCaseObj[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
    }
  }
  return snakeCaseObj;
}
