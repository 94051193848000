import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "../apollo.fragment";

export const APPLY_MULTIPLE_JOBS_MUTATION = gql`
	mutation createMultiJobApplicants($jobIds: [String!]!, $source: JobApplicantSourceEnum, $note: String, $applicantTimeOffs: [ApplicantTimeOffArgument!] ) {
		createMultiJobApplicants(jobIds: $jobIds, note: $note, source: $source, applicantTimeOffs: $applicantTimeOffs) {
			id
		}
	}
`

export const EXTEND_JOB_APPLICANT_V2_MUTATION = gql`
	mutation CreateExtendAssignmentV2($workerAssignmentId: String!, $note: String, $applicantTimeOffs: [ApplicantTimeOffArgument!]) {
		createExtendAssignmentV2(workerAssignmentId: $workerAssignmentId, note: $note, applicantTimeOffs: $applicantTimeOffs) {
			id
		}
	}
`

export const LIKE_DISLIKE_JOB_MUTATION = gql`
	mutation LikeOrDislikeAJob($jobId: String!, $action: LikeActionEnum!) {
		likeOrDislikeAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

export const QUICK_APPLY_JOB_MUTATION = gql`
	mutation quickApplyJob(
		$firstName: String!
		$lastName: String!
		$email: String!
		$phone: String!
		$password: String!
		$passwordConfirmation: String!
		$recaptchaToken: String
		$workerSpecialtiesAttributes: [WorkerSpecialtyArgument!]
		$workingPreferredLocationsAttributes: [WorkingPreferredLocationArgument!]
		$externalJobId: String!
	) {
		createIntegrationWorker(
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			password: $password
			passwordConfirmation: $passwordConfirmation
			recaptchaToken: $recaptchaToken
			workerSpecialtiesAttributes: $workerSpecialtiesAttributes
			workingPreferredLocationsAttributes: $workingPreferredLocationsAttributes
			externalJobId: $externalJobId
		) {
			authToken
			notificationChannel
			worker {
				phoneVerifiedAt
				firstName
				lastName
				id
				email
				phone
				workerSpecialties {
					specialty
				}
				workerNotes {
					note
				}
			}
		}
	}
`