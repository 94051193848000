import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import { bookMarkEventsTracking } from '../constants'

export const redirectEventTrackingBookMark = (bookMarkEvents, data) => {
	switch (bookMarkEvents) {
		case bookMarkEventsTracking.HOMEPAGE_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.HomePageClickBookmarkSuccessEvent(data))
		case bookMarkEventsTracking.POPULAR_CITY_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.PopularCityClickBookmarkSuccessEvent(data))
		case bookMarkEventsTracking.SEE_MORE_JOBS_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.SeeMoreJobClickBookmarkSuccessEvent(data))
		case bookMarkEventsTracking.FIND_JOB_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.FindJobClickBookmarkSuccessEvent(data))
		case bookMarkEventsTracking.JOB_DETAIL_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.JobDetailClickBookMarkSuccessEvent(data))
		case bookMarkEventsTracking.FIND_JOB_RESULTS_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.FindJobResultsClickBookmarkSuccessEvent(data))
		case bookMarkEventsTracking.FAVOURITE_TAB_CLICK_BOOKMARK_SUCCESS:
			return eventClient.logEvent(new events.FavoriteClickBookMarkSuccessEvent(data))
		default:
			return eventClient.logEvent(new events.FavoriteClickBookMarkSuccessEvent(data))
	}
}
