import { gql } from "@apollo/client";

export const EMERGENCY_CONTACT_FRAGMENT = gql`
  fragment emergencyContactFragment on EmergencyContact {
    id
    contactName
    contactPhone
    contactRelationship
  }
`;

export const WORKER_ADDRESS_FRAGMENT = gql`
  fragment workerAddressFragment on WorkerAddress {
    id
    zipcode
    street
    default
    city
    state
    country
  }
`;

export const WORKER_FRAGMENT = gql`
  fragment workerFragment on Worker {
    id
    email
    firstName
    lastName
    avatarUrl
    phone
    phoneVerifiedAt
    workAuthorized
    maidenName
    company {
      companyConfig {
        adpUrl
        companyId
        oneSignalAppId
      }
    }
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment locationFragment on Location {
    id
    address
    addressState
    city
    country
    zipcode
  }
`;

export const JOB_FRAGMENT = gql`
  fragment jobFragment on Job {
    id
    matchingPercentage
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyFragment on Company {
    id
    name
    phone
    address
    addressState
    city
    country
    zip
    description
    mainContactEmail
    mainContactName
  }
`;

export const WORKER_CREDENTIAL_FRAGMENT = gql`
  fragment workerCredentialFragment on WorkerCredential {
    company {
      ...companyFragment
    }
    createdAt
    expirationDate
    id
    name
    status
    worker {
      ...workerFragment
    }
  }
  ${WORKER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
  fragment workingAuthorizationFragment on WorkingAuthorization {
    id
    dateOfBirth
    socialSecurityNumber
  }
`;

export const WORK_EXPERIENCE_FRAGMENT = gql`
  fragment workExperienceFragment on WorkExperience {
    id
    startDate
    endDate
    facilityName
    unitSpecialty
    unitsFloatedTo
    floated
    chartingSoftwareUsed
    travelAssignment
    agency
    currentlyEmployed
    createdAt
    city
    state
    country
    patientRatio
    bedsInUnit
  }
`;

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
  fragment salesforceIntegrationFragment on SalesforceIntegration {
    id
    companyId
    businessDay
    weekending
  }
`;

export const WORKED_TIMESHEET_FRAGMENT = gql`
  fragment workedTimesheetFragment on WorkedTimesheet {
    id
    startDate
    endDate
    status
    updatedAt
  }
`;

export const ONBOARDING_PART_FRAGMENT = gql`
  fragment onboardingPartFragment on OnboardingPart {
    id
    name
    description
  }
`;
export const DIRECT_UPLOAD_FRAGMENT = gql`
  fragment directUploadFragment on DirectUpload {
    blobId
    headers
    signedBlobId
    url
  }
`;

export const WORKER_CERTIFICATION_FRAGMENT = gql`
  fragment workerCertificationFragment on WorkerCertification {
    id
    title
    licenseDiscipline
    licenseState
    licenseNumber
    compactLicense
    expirationDate
    certificationType
    createdAt
    licenseImages {
      id
      contentType
      filename
      imageUrls(size: "100x100")
    }
  }
`;

export const WORKER_REFERENCE_FRAGMENT = gql`
  fragment workerReferenceFragment on WorkerReference {
    id
    facilityName
    contactFullName
    contactEmail
    contactPhone
    waitToContact
    jobTitle
    createdAt
  }
`;
export const IMAGE_FILE_FRAGMENT = gql`
  fragment imageFileFragment on File {
    id
    fileUrl
    contentType
  }
`;

export const FILE_FRAGMENT = gql`
  fragment fileFragment on File {
    id
    filename
    fileUrl
    contentType
  }
`;

export const WORKER_EDUCATION_FRAGMENT = gql`
  fragment workerEducationFragment on WorkerEducation {
    id
    schoolName
    major
    state
    city
    country
    degreeName
    degreeDate
  }
`;

export const LICENSE_IMAGES_FRAGMENT = gql`
  fragment licenseImagesFragment on File {
    id
    fileUrl
    contentType
  }
`;

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
  fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
    id
    workerCertifications {
      ...workerCertificationFragment
    }
  }

  ${WORKER_CERTIFICATION_FRAGMENT}
`;

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
  fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
    id
    workerReferences {
      ...workerReferenceFragment
    }
  }

  ${WORKER_REFERENCE_FRAGMENT}
`;

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
  fragment workerEducationOverviewFragment on WorkerEducationOverview {
    id
    workerEducations {
      ...workerEducationFragment
    }
  }

  ${WORKER_EDUCATION_FRAGMENT}
`;

export const WORK_EXPERIENCE_OVERVIEW_FRAMENT = gql`
  fragment workExperienceOverview on WorkExperienceOverview {
    id
    discipline
    primarySpecialty
    secondarySpecialty
    yearsOfExperience
    workExperiences {
      ...workExperienceFragment
    }
  }

  ${WORK_EXPERIENCE_FRAGMENT}
`;

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
  fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
    ... on WorkerCertificationOverview {
      ...workerCertificationOverviewFragment
    }

    ... on WorkExperienceOverview {
      ...workExperienceOverview
    }

    ... on WorkerReferenceOverview {
      ...workerReferenceOverviewFragment
    }

    ... on WorkerEducationOverview {
      ...workerEducationOverviewFragment
    }

    ... on UserTermsOfService {
      id
      agreedAt
      termsOfService {
        id
        url
        version
      }
    }

    ... on CombinationOnboardingPart {
      id
      workerAddress {
        ...workerAddressFragment
      }
      workingAuthorization {
        ...workingAuthorizationFragment
      }
      workingPreferredLocations {
        id
        preferredWorkingState
      }
      emergencyContact {
        ...emergencyContactFragment
      }
    }
  }

  ${WORKER_CETIFICATION_OVERVIEW_FRAGMENT}
  ${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
  ${WORKER_REFERENCE_OVERVIEW_FRAGMENT}
  ${WORKER_EDUCATION_OVERVIEW_FRAGMENT}
  ${WORKER_ADDRESS_FRAGMENT}
  ${WORKING_AUTHORIZATION_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
`;

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
  fragment workerOnboardingPartFragment on WorkerOnboardingPart {
    id
    integrationConfigurations
    integrationObject
    state
    integration {
      ...workerOnboardingPartIntegrationFragment
    }
  }

  ${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`;

export const REFERRED_TRAVELER_FRAGMENT = gql`
  fragment referredTravelerFragment on ReferredTraveler {
    id
    firstName
    lastName
    email
    phone
    relationship
    discipline
  }
`;

export const RECRUITER_FRAGMENT = gql`
  fragment recruiterFragment on Recruiter {
    companyId
    email
    id
    name
    phone
    title
    company {
      ...companyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const EMPLOYER_FRAGMENT = gql`
  fragment employerFragment on Employer {
    id
    email
    firstName
    lastName
    phone
    title
    agreedToTerms
    avatarUrl
    company {
      ...companyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const WORKER_ASSIGNMENT_FRAGMENT = gql`
  fragment workerAssignmentFragment on WorkerAssignment {
    id
    startDate
    endDate
    status
    client {
      id
      name
    }
    job {
      ...jobFragment
    }
    jobApplicant {
      applyDate
      createdAt
      id
      note
      source
      status
      submittal {
        externalUpdatedAt
        id
        jobId
        status
        submittalDate
      }
      timeOffEndDate
      timeOffStartDate
      updatedAt
      applicationProcess
    }
  }
  ${JOB_FRAGMENT}
`;

export const SHIFT_REQUEST_FRAGMENT = gql`
  fragment shiftRequestFragment on ShiftRequest {
    id
    name
    state
    companyId
    description
    payAmount
    requirements
    note
    matchingPercentage
    shiftTypes
    externalId
    exclusiveJob
    facility {
      id
      name
    }
    specialties {
      id
      name
    }
    promoText
    location {
      id
      active
      addressState
      city
      geofencingRadius
      latitude
      longitude
      phone
      zipcode
      timezone
      country
    }
  }
`;

export const MESSAGE_FRAGMENT = gql`
  fragment messageFragment on Message {
    id
    attachmentUrl
    createdAt
    jobName
    jobStartTime
    jobTimezone
    message
    objectId
    objectType
    receiver {
      id
      avatarUrl
      email
      firstName
      lastName
      phone
    }
    receiverId
    receiverName
    receiverType
    sender {
      id
      avatarUrl
      email
      firstName
      lastName
      phone
    }
    senderId
    senderName
    senderType
    status
  }
`;

export const SHIFT_FRAGMENT = gql`
  fragment shiftFragment on Shift {
    id
    approvedAt
    breakTimeDuration
    breakTimeNumber
    createdAt
    cancelledAt
    duration
    jobsNumber
    note
    state
    payAmount
    maximumPayAmount
    paymentType
    startTime
    endTime
    shiftRequestId
    fillRate
    permittedEvents
    timezone
    workingHours
    estimatedWorkingHours
    shiftType
    hourlyPay
    shiftRequest {
      id
      name
      state
      companyId
      assignedEmployerId
      invitedWorkerIds
      description
      requirements
      note
      badgeRequirements
      employerId
      facilityId
      facilityGroupId
      shiftCategoryId
      permittedEvents
      specialties {
        id
        name
      }
      promoText
    }
    location {
      id
      active
      addressState
      city
      facilityId
      facilityGroupId
      geofencingRadius
      latitude
      longitude
      phone
      zipcode
      timezone
      country
      logoUrl
    }
    facilityShift {
      id
      name
    }
    facility {
      id
      name
    }
  }
`;
