import { css } from "styled-components";

export const notifyDialogStyle = css`
  .MuiDialog-paper {
    margin: 8px;

    > .MuiBox-root {
      min-width: 340px;

      .header {
        font-weight: 700;
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
  .content-notify {
    font-family: "P22 Mackinac Pro";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.content.veryDark};
  }
`;
