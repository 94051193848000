import React, { Suspense } from "react";
import { Route as BaseRoute, Redirect } from "react-router-dom";
import { observer } from "~/common/mobx.decorator";
import { Backdrop, Box, CircularProgress, Zoom } from "@material-ui/core";
import { PATHS } from "~/common/constants";

const RouteComponent = observer(
  ({
    auth,
    feature,
    component: Component,
    transition: Transition,
    ...props
  }) => {
    if (!Component) {
      return <Redirect to={PATHS.common.home} />;
    }

    return (
      <Suspense
        fallback={
          <Backdrop open={true}>
            <CircularProgress />
          </Backdrop>
        }
      >
        {Transition ? (
          <Transition in>
            <Box>
              <Component {...props} />
            </Box>
          </Transition>
        ) : (
          <Component {...props} />
        )}
      </Suspense>
    );
  }
);

export const Route = ({ auth, feature, component, transition, ...props }) => (
  <BaseRoute
    {...props}
    render={(rest) => (
      <RouteComponent
        {...rest}
        component={component}
        auth={auth}
        feature={feature}
        transition={transition}
      />
    )}
  />
);

Route.defaultProps = {
  transition: Zoom,
};
