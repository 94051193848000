import { BaseAdapter } from "@opus/web.core.lib.event-tracking/dist/adapters";
import { authStore } from "~/stores/auth.store";
import { httpRequestTracking } from "~/common/tracking/http.tracking";

class OpusTrackingAdapter extends BaseAdapter {
  logEvent(type, payload) {
    const params = {
      data: {
        ...payload,
        is_anonymous: authStore.isAnonymous,
        user_id: authStore.id,
      },
      is_anonymous: authStore.isAnonymous,
      name: type,
    };

    return httpRequestTracking.record(params);
  }

  setUserId(_id) {
    return new Promise(() => {});
  }

  setUserProperties() {
    return new Promise(() => {});
  }
}

export { OpusTrackingAdapter };
