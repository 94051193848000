import React, { Suspense } from "react";
import { ThemeProvider } from "styled-components";
import {
  NoSsr,
  ThemeProvider as MuiThemeProvider,
  LinearProgress,
  CssBaseline,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { LocalizationProvider } from "@material-ui/pickers";
import { LanguageProvider } from "@opus/web.core.hooks.use-translation";
import { AppRoute } from "./app.route";
import { Router } from "react-router-dom";
import { history, appStore, routerStore } from "~/stores";
import { observer, loadingStore, loadingState } from "~/common/mobx.decorator";
import { GlobalStyle, linearProgressStyle } from "./app.style";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "~/common/apollo";
import { THEMES } from "~/themes";
import { SnackbarProvider } from "notistack";
import { GlobalNotify } from "~/components/notify";
import dateAdapter from "@material-ui/pickers/adapter/moment";
import moment from "moment";
import { ACTIONS } from "./common/constants";
import { useEffectOnce } from "react-use";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LanguagePlural from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(LanguagePlural)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(
    {
      fallbackLng: "en",
      lng: "en",
      defaultNS: "translation",
      fallbackNS: "translation",

      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      backend: {
        loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json",
        addPath: process.env.PUBLIC_URL + "/locales/add/{{lng}}/{{ns}}",
        queryStringParams: { version: process.env.REACT_APP_CI_COMMIT_TAG },
      },
    },
    (_, t) => {
      const locale = t("$VALIDATION", { returnObjects: true });
      setLocale(locale);
    }
  );

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    w: "1 week",
    ww: "%d weeks",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

export const App = observer(() => {
  const loading = appStore.companyConfig
    ? false
    : loadingState(ACTIONS.appStore.init) || !appStore.ready;

  useEffectOnce(() => {
    appStore.init();
  });

  return (
    <Router history={history}>
      <Suspense
        fallback={
          <Backdrop open={true}>
            <CircularProgress />
          </Backdrop>
        }
      >
        <NoSsr>
          <ApolloProvider client={apolloClient}>
            <LanguageProvider value="care">
              <MuiThemeProvider theme={THEMES.worker}>
                <ThemeProvider theme={THEMES.worker}>
                  <SnackbarProvider
                    maxSnack={3}
                    preventDuplicate
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <LocalizationProvider dateAdapter={dateAdapter}>
                      <CssBaseline />
                      <GlobalStyle />
                      <GlobalNotify />
                      {loadingStore.global && !routerStore.isEmbedded && (
                        <LinearProgress css={linearProgressStyle} />
                      )}
                      {!loading && <AppRoute />}
                    </LocalizationProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </LanguageProvider>
          </ApolloProvider>
        </NoSsr>
      </Suspense>
    </Router>
  );
});
